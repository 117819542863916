import { useEffect, useState, useMemo } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";

import Default_Profile from "assets/img/default_profile.png";
import "bootstrap-daterangepicker/daterangepicker.css";
import countriesOption from "countries";
// react-bootstrap components
import API from "api";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "assets/img/edit-icon.png";
import ErrorMessage from "components/Message/ErrorMessage";
import RadioButton from "components/RadioButton/RadioButton";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { countryListAllIsoData, TEST_TYPE_VALUE } from "constant";
import { Button, Col, Modal, Row } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import GQAPI from "views/GraphQL/gqApi";
import { parseJSONObject, mergeArraysById } from "utils";
import ReactPDF from "@react-pdf/renderer";
import api from "api";
import { Storage, label } from "aws-amplify";
import DocumentForm from "components/Document/DocumentForm";
import DocumentList from "components/Document/DocumentList";
import ImageView from "components/Image/ImageView";
import ImageInput from "components/Input/ImageInput";
import InputField from "components/InputField/InputField";
import Loader from "components/Loader/Loader";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import InsuranceDetailsModal from "components/Patient/InsuranceDetailsModal";
import InsuranceViewCard from "components/Patient/InsuranceViewCard";
import PDF from "components/PDF";
import ProviderModal from "components/Modal/ProviderModal";
import {
  CONFIG,
  CUSTOM_INS,
  EMP_INS_OBJECT,
  NEW_PROVIDER_OBJECT,
  INSURANCE_EMPTY_OBJECT,
  EMPLOYEE,
  ALTA_NEW_ID,
} from "constant";
import graphQLApi from "graphQLApi";
import moment from "moment";
import "react-activity/dist/Bounce.css";
import "react-tabs/style/react-tabs.css";
import {
  convertToLower,
  formatDateOfBirthDOB,
  formatPhoneNumber,
  formatTest,
  getDemoGraphics,
  getPhoneNo,
  getValidDep,
  getValidSpaces,
  isValidEmail,
  isValidIDNumber,
  isValidName,
  isValidPhoneWithCode,
  isValidUrl,
  parseInsDetails,
  setPhoneNo,
  userCompanyID,
  formatCurrency,
} from "utils";
import ProfileRelationModal from "views/Patient/ProfileRelationModalOnModal";
import { BILLING_TYPES_OPT } from "constant";
import { generatePassword } from "utils";
import { emailMsgToCreatedMember } from "utils";
import { connectedIDInLocalStorage } from "utils";
import { setOpenInsuranceModal, setSelectedInsurance } from "../../store/features/insurances/insuranceSlice";
import { fetchAllProviders } from "store/features/providers/providersSlice";
import { providerOptionList } from "../../store/features/providers/providersSelectors";
import RelationView from "views/Patient/RelationView";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import MainTable from "components/Table/MainTable";
import { TABLE_QUICK_TOOLS, IGNORE_INS, MESSAGE_MODES } from "constant";
import { v4 as uuidv4 } from "uuid";
import { getUUID } from "utils";
import { setMessage } from "store/features/general/generalAction";
import { setProxyRelations } from "store/features/employees/employeesSlice";
import { proxyRelationsSelector } from "store/features/employees/employeesSelectors";
import { selectClient } from "store/features/authentication/authenticationSelectors";
import { locationsSelectors } from "store/features/locations/locationsSelectors";

import { validatePatientRecordFromClient } from "utils";

const PatientProfileModal = (props) => {
  const { patient, handleClose, appContext } = props;
  const location = useLocation();
  const history = useHistory();
  const { state } = location;
  const [test, setTest] = useState();
  const [site, setSite] = useState();
  const [lab, setLab] = useState();
  const [siteContactPhone, setSiteContactPhone] = useState();
  const [demographics, setDemographics] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [releaseTo, setReleaseTo] = useState([]);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [signature, setSignature] = useState();
  const [loading, setLoading] = useState(false);
  const [hippaConsent, setHippaConsent] = useState(false);
  const [showImages, setShowImages] = useState(null);
  const [showSubmitAlert, setShowSubmitAlert] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const [editInfo, setEditInfo] = useState();
  const [Employee, setEmployee] = useState();

  const [error, setError] = useState("");
  const [openQuarantined, setOpenQuaratined] = useState(false);
  const [allowToEditQuarantine, setAllowToEditQuarantine] = useState(false);
  const [openDataChangeConfirmation, setOpenDataChangeConfirmation] = useState(false);
  const [patternConsent, setPatternConsent] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [testViews, setTestViews] = useState([]);
  const [employeeLogs, setEmployeeLogs] = useState([]);
  const [loadingTestView, setLoadingTestView] = useState(false);
  const [vaccinationImage, setVaccinationImage] = useState("");
  const [externalTestImage, setExternalTestImage] = useState("");
  const [changeData, setchangeData] = useState({});
  const [changeDataCheck, setchangeDataCheck] = useState(true);
  const [profileImage, setProfileImage] = useState(Default_Profile);
  const [showProfileModal, setProfileModal] = useState(false);
  const [openDocumentModal, setOpenDocumentModal] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState({ isNew: true });
  const [documents, setDocuments] = useState([]);
  const [insuranceModal, setInsuranceModal] = useState(null);
  const [newProviderInfo, setNewUserProvider] = useState(null);
  const [relations, setRelations] = useState([]);

  const getProviderOption = useSelector(providerOptionList);
  const userClientData = useSelector(selectClient);
  const CLIENT_OPTIONS = useSelector(locationsSelectors).map((f) => ({ label: f.name, value: f.id }));

  const clientOptions = useMemo(() => {
    const filteredRecord = CONFIG.siteID ? CLIENT_OPTIONS.filter((s) => s.value === CONFIG.siteID) : CLIENT_OPTIONS;

    return filteredRecord;
  }, [CLIENT_OPTIONS, CONFIG.siteID]);

  const providers = useMemo(() => {
    const filteredProviders = CONFIG.siteID
      ? getProviderOption.filter((s) => s.associatedClient === CONFIG.siteID)
      : getProviderOption;

    return (filteredProviders ? [...filteredProviders] : []).sort((a, b) => a.name.localeCompare(b.name));
  }, [getProviderOption, CONFIG.siteID]);

  const permission = useSelector(userGivenPermission);
  const proxyRelations = useSelector(proxyRelationsSelector);

  const dispatch = useDispatch();

  const hideAlert = () => {
    props.history.push("/admin/pendingrelease");
    setShowAlert(false);
  };

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setEmployee({ ...Employee, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setEmployee({ ...Employee, ...addressObj });
    }
  };

  const loadDocument = async (id) => {
    try {
      const res = await api.fetchDocumentAPI({ employeeID: id });
      setDocuments(res);
    } catch (err) {
      console.log("Error:-", err);
    }
  };

  const getPatient = async (id) => {
    if (!id) return;
    try {
      const empObject = await API.getTestByID(id, "employeeDetails");

      if (empObject) {
        empObject.employee_demographics = empObject;
        setEditInfo(empObject);
        setDemographics({ ...getDemoGraphics(empObject), isNew: false });
        setEmployee({
          ...getDemoGraphics(empObject),
          isNew: false,
          previousSiteID: getDemoGraphics(empObject)?.siteID || "",
          ...(Employee?.requestID && { requestID: Employee?.requestID }),
        });
        setTestViews([]);
        const conntedTo = parseJSONObject(empObject.connectedTo || []);

        const formattedRelationalData = mergeArraysById(conntedTo, empObject?.proxyProfiles);
        dispatch(setProxyRelations(formattedRelationalData));
        loadDocument(empObject.id);
      }
    } catch (err) {
      console.log("Error:-", err);
    }
  };

  useEffect(() => {
    if (patient && patient.isNew) {
      setDemographics({ ...patient, insuranceDetails: parseInsDetails(patient.insuranceDetails, patient) });
      const data1 = { ...patient, id: uuidv4(), insuranceDetails: parseInsDetails(patient.insuranceDetails, patient) };
      dispatch(setProxyRelations([]));
      setEmployee(data1);
    }
    if (patient && patient.mdID) {
      getPatient(patient.mdID);
    }
    getProviders();
  }, [patient]);

  const triggerNewData = (data) => {
    if (data.id !== patient.mdID) return;
    if (!editButton) {
      setchangeData(data);
      setOpenDataChangeConfirmation(true);
    } else {
      setEditInfo(data);
      setDemographics(getDemoGraphics(data));
      setEmployee(getDemoGraphics(data));
      setTest(data);
    }
  };

  // SETTING UP LISTNER
  useEffect(() => {
    if (changeDataCheck && appContext?.testObject && appContext.testObject?.opType === "UPDATE") {
      triggerNewData(appContext.testObject.element);
    }
  }, [appContext?.testObject]);

  // SETTING UP LISTNER
  useEffect(() => {
    if (changeDataCheck && appContext?.eventObject && appContext.eventObject?.opType === "UPDATE") {
      triggerNewData(appContext.eventObject.element);
    }
  }, [appContext?.eventObject]);

  const handleCheckEligibility = async (emp) => {
    if (!emp) return;

    if (IGNORE_INS.includes(emp.insuranceCompanyCode)) {
      dispatch(setMessage("Payer does not support realtime eligibility.", MESSAGE_MODES.info));
      return;
    }

    setLoading(true);

    try {
      const eligbRes = await api.checkEligibility({ ...emp, mdID: emp.id });
      const { data, error = null, message, status } = eligbRes;

      if (error) {
        dispatch(setMessage(error?.error_mesg || message, MESSAGE_MODES.info));
      } else if (status === "Ineligible") {
        dispatch(setMessage(message, MESSAGE_MODES.info));
      } else if (data) {
        dispatch(setMessage("Patient is Eligible", MESSAGE_MODES.info));

        if (emp.requestID) {
          await api.updateUploadRequest(emp.requestID, { record: { ...emp }, eligibiltyStatus: res });
        }
      }
    } catch (err) {
      console.log("Error: -", err);
    } finally {
      setLoading(false);
    }
  };

  const getProviders = async (test) => {
    dispatch(fetchAllProviders());
  };

  const handleDataChangeConfirm = async (isConfirm) => {
    if (isConfirm) {
      setOpenDataChangeConfirmation(false);
      setEditInfo(changeData);
      setDemographics(getDemoGraphics(changeData));
      setEmployee(getDemoGraphics(changeData));
      setTest(changeData);
    } else {
      setOpenDataChangeConfirmation(false);
    }
  };

  useEffect(() => {
    if (appContext.user) setAllowToEditQuarantine(appContext.user.isSite() || appContext.user.isClient());
  }, [appContext.user]);

  const hasKeyOfSMS = (model) => {
    if (model.hasOwnProperty("sendSMS")) {
      let sendSMS = model?.sendSMS || model.sendSMS == "1" ? true : false;
      return sendSMS;
    }
    return true;
  };

  const hasKeyOfEmail = (model) => {
    if (model.hasOwnProperty("sendEmail")) {
      let sendEmail = model?.sendEmail || model.sendEmail == "1" ? true : false;
      return sendEmail;
    }
    return true;
  };

  // const getFileFromStorage = async (file, setImage) => {
  //   if (file) {
  //     try {
  //       const path = await Storage.get(file);
  //       setImage(path);
  //       console.log("path", path);
  //       return true;
  //     } catch {
  //       console.log("Image loading fail");
  //     }
  //   } else {
  //     return false;
  //   }
  // };

  // useEffect(async () => {

  // }, []);

  const updateContactPreferences = (employee) => {
    const { phone_number, email } = employee;

    return {
      ...employee,
      sendSMS: phone_number ? "1" : "0",
      sendEmail: email ? "1" : "0",
    };
  };

  const handlePhoneNo = (e) => {
    let updatedEmployee = { ...Employee, phone_number: e };

    updatedEmployee = updateContactPreferences(updatedEmployee);
    setEmployee(updatedEmployee);
  };

  const handleViewTest = async (id) => {
    setLoadingTestView(true);
    const tests = await API.getAdminTestList(id);
    setTestViews(tests.filter((t) => !t._deleted));
    setLoadingTestView(false);
  };

  // handle employee logs
  const handleEmployeeLogs = async (id) => {
    let employeeLogs = await API.getProfileUpdateLogs(id);
    employeeLogs = employeeLogs.sort((a, b) => (b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0));
    setEmployeeLogs(employeeLogs);
  };

  //   useEffect(() => {
  //     if (editInfo && editInfo.employee_demographics) {
  //       const emp = getDemoGraphics(editInfo);
  //       const list = countryListAllIsoData.find((f) => f.name === emp.country) || { code: "US" };
  //       setEmployee(emp);
  //       setDemographics({ ...emp, isoCode: list.code });
  //     }
  //   }, [editInfo]);

  useEffect(() => {
    const getSite = async (id) => {
      const model = await API.findSiteByID(id);
      model.length > 0 && setSite(model[0]);
    };

    const getSignature = async (test) => {
      const imgObject = [];

      try {
        const siteID = test.baseSiteID || test.siteID;
        const fileName = `${siteID}&&${test.id}&&sig.png`;
        const sigt = await Storage.get(fileName);
        setSignature(sigt);
        imgObject.push({ title: "HIPPA Consent", isHIPPA: true, imgSrc: sigt });

        const sig = await Storage.get(`${test.id}&&${siteID}&&ins-f.jpeg`);
        const isValidInsF = await isValidUrl(sig);
        if (sig) imgObject.push({ title: "Insurance Card Front", isHIPPA: false, imgSrc: sig, isExist: isValidInsF });
        const sig1 = await Storage.get(`${test.id}&&${siteID}&&ins-b.jpeg`);
        const isValidInsB = await isValidUrl(sig1);
        if (sig1) imgObject.push({ title: "Insurance Card Back", isHIPPA: false, imgSrc: sig1, isExist: isValidInsB });
      } catch (error) {
        console.log("Image Error", error);
      }

      if (imgObject.length > 0) {
        setShowImages(imgObject);
      }
      setHippaConsent(true);
    };

    // if (test) {
    //   setSite(getSite(test.siteID));
    //   appContext.getLab(test).then((resp) => setLab(resp));
    //   getSignature(test);
    // }

    setLoading(false);
  }, [test]);

  useEffect(() => {
    site && setSiteContactPhone(site.contact_phone);
  }, [site]);

  function calculate_age(dob) {
    const val = formatDateOfBirthDOB(dob);
    const years = moment().diff(val, "years", false);
    return years;
  }

  const captalizeFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatIDNo = () => {
    if (calculate_age(demographics?.dob) < 18) {
      const id = demographics?.id_number;
      if (id && id.length > 5) {
        return `${id.substring(0, 3)}-${id.substring(3, 5)}-${id.substring(5)}`;
      }
      if (id && id.length > 3) {
        return `${id.substring(0, 3)}-${id.substring(3)}`;
      }
      return demographics?.id_number;
    }
    return demographics?.id_number;
  };

  const showSubmitButton = () => {
    if (!test) return true;
    const st = test.status.toLowerCase();
    if (st === "new") return true;
    if (st === "pending" && !test.result) return true;
    return false;
  };
  const ImageUpload = async (fileName, image) => {
    if (image instanceof Object) {
      await Storage.put(fileName, image);
      return fileName;
    } else {
      return "";
    }
  };

  let validationRules = {
    first: true,
    last: true,
    email: true,
    phone_number: true,
    dob: true,
    sex: true,
    insuranceCompanyCode: true,
    provider: true,
  };

  const validationOfPatientRecord = (Employee, fromReset = false) => {
    if (userClientData?.requiredKeys?.employee) {
      validationRules = userClientData?.requiredKeys?.employee;
    }
    const errorMsg = validatePatientRecordFromClient(Employee, validationRules, fromReset);
    if (errorMsg) {
      setError(errorMsg);
      return false;
    }
    return true;
  };

  const requiredKeysObj = useMemo(() => {
    return userClientData?.requiredKeys?.employee || validationRules;
  }, [userClientData, validationRules]);

  const getSiteOption = (emp) => {
    if (!clientOptions || !Array.isArray(clientOptions)) return { site: null, siteList: null };

    if (CONFIG.siteID) {
      const site = clientOptions[0];
      const siteList = emp.provider ? emp.provider : site ? [{ value: site.value, label: site.label }] : null;
      return { site, siteList };
    }

    if (emp.provider && Array.isArray(emp.provider)) {
      const site = clientOptions.find((s) => s.value === emp.provider[0].value);
      const siteList = emp.provider;
      return { site, siteList };
    }

    if (emp.siteID) {
      const site = clientOptions.find((s) => s.value === emp.siteID);
      const siteList = emp.provider ? emp.provider : site ? [{ value: emp.siteID, label: emp.site_name }] : null;
      return { site, siteList };
    }

    return { site: null, siteList: null };
  };

  const handleSave = async (Employee) => {
    let phone_number = "";
    let countryCode = "";
    if (!Employee) return;

    if (Employee.insuranceDetails?.length > 0) {
      const ins = Employee.insuranceDetails[0];
      Object.assign(Employee, {
        ...Employee,
        insurance_name: ins.insuranceCompanyCode,
        medicalNo: ins.medicareNo,
        insurance_number: ins.medicalNo,
        insuranceCompanyCode: ins.insuranceCompany,
      });
    } else {
      Object.assign(Employee, {
        ...Employee,
        insurance_name: "",
        medicalNo: "",
        insurance_number: "",
        insuranceCompanyCode: "",
      });
    }

    if (!validationOfPatientRecord(Employee)) {
      return;
    }

    // FOR PHONE CHECK COUTRY CODE FOR INTERNATIONAL RECORD VERIFICATION
    const phone = formatPhoneNumberIntl(Employee.phone_number);
    const phoneArr = phone.split(" ");
    // check Country code exist

    if (phoneArr[0].includes("+")) {
      let [cc, pNo] = getPhoneNo(Employee.phone_number, phoneArr[0]);
      phone_number = pNo;
      countryCode = cc;
    } else {
      phone_number = Employee.phone_number;
      countryCode = Employee.countryCode ? Employee.countryCode : "+1";
    }

    if (phoneError || !isValidPhoneWithCode(`${countryCode}${phone_number}`)) {
      setError("Invalid Phone Number");
      return;
    }
    if (Employee.dob === "Invalid date") {
      setError("Invalid Date of Birth");
      return;
    }

    // if (!Employee.id_number) {
    //   setError(`${calculate_age(Employee.dob) < 18 ? "SSN" : "ID Number"} is required`);
    //   return;
    // }
    // if (!isValidIdNum(Employee.id_number)) {
    //   setError(`Invalid ${calculate_age(Employee.dob) < 18 ? "SSN" : "ID Number"} must contains at least 5 characters`);
    //   return;
    // }

    setLoading(true);
    setchangeDataCheck(false);

    // if Patient is New Create Patient with proxyContact APi else Update
    const conncetedProvider = providers?.find((f) => f.id === Employee?.referringProvider);

    const { site, siteList } = getSiteOption(Employee); // appContext.sites?.find((f) => f.id === conncetedProvider?.associatedClient);

    const emp = {
      ...Employee,
      dob: moment(Employee.dob).format("MMDDYYYY"),
      countryCode: countryCode,
      firstName: Employee.first,
      lastName: Employee.last,
      updatedBy: appContext.user.sub,
      updatedByName: appContext.user.name,
      phone_number: phone_number,
      ...(siteList && {
        provider: siteList,
      }),
      ...(site && {
        siteID: site?.id || site?.value,
        site_name: site?.name || site?.label,
      }),
    };

    try {
      let result = await api.proxyContantFromPatient({
        employee_demographics: emp,
        phoneNumber: phone_number,
        countryCode: countryCode,
        updatedBy: appContext.user.sub,
        updatedByName: appContext.user.name,
        clientID: CONFIG.clientID,
        siteID: CONFIG.siteID || Employee.siteID,
        employeeID: patient.mdID,
        customID: emp.isNew ? emp.id : null,
      });

      if (!result || result?.message) {
        setError(result?.message || "Fail to save the Employee Record");
        setLoading(false);
        return;
      }

      const { record } = result;

      if (Employee.isCheckedUser && result) {
        await createPatientLogin({ ...emp, id: record.id }, record);
      }

      if (Employee.requestID && Employee?.isNew) {
        await api.updateUploadRequest(Employee.requestID, result);
      }

      if (Employee.orderDate && Employee?.isNew) {
        const client = appContext.clients?.find((f) => f.id === Employee?.clientID);
        const lab = appContext.labs?.find((f) => f.id === CONFIG.whiteLabelID);
        const demo = {
          city: Employee.city,
          dob: Employee.dob,
          email: Employee.email,
          firstName: Employee.first,
          idNumber: Employee.idNumber ? Employee.idNumber : "",
          insurance_name: Employee.insurance_name || "",
          insurance_number: Employee.insurance_number || "",
          insuranceGroupId: Employee.insuranceGroupId || "",
          insuranceCompanyCode: Employee.insuranceCompanyCode || "",
          isVaccinated: Employee.isVaccinated || false,
          lastName: Employee.last || "",
          phoneNumber: Employee.phone_number || "",
          countryCode: Employee?.countryCode || "",
          country: Employee?.country || "",
          sex: Employee.sex || "",
          state: Employee.state || "",
          street: Employee.street || "",
          street2: Employee.street2 || "",
          zip: Employee.zip || "",
          mdID: record?.id,
        };
        const order = {
          employee_demographics: { ...demo },
          createdBy: appContext.user.sub,
          createdByName: appContext.user.name,
          clientID: client?.id,
          siteID: site?.id,
          clientName: client?.name,
          site_name: site?.contact_name,
          employeeID: record?.id,
          labID: lab?.id,
          floorNo: Employee.floorNo,
          labName: lab?.name,
          orderDate: Employee?.orderDate,
          test_type: "PCR",
          status: "Pending",
        };
        await GQAPI.createOrderAPI(order);
      }

      if (!record && Employee?.isNew) {
        appContext.showErrorMessage("Patient Record Already Exist");
        setLoading(false);
        return;
      }
      if (Employee?.isNew) {
        appContext.showSuccessBottomMessage("Patient Created Successfully");
      } else {
        appContext.showSuccessBottomMessage("Patient Updated Successfully");
      }
      setLoading(false);
    } catch (err) {
      console.log("create Patient", err);
      setLoading(false);
    }

    handleClose(Employee);
    return;
  };

  const createPatientLogin = async (Employee, result) => {
    setLoading(true);
    if (!Employee.email) {
      appContext.showErrorMessage("Email required for create patient portal access.");
      return;
    }

    try {
      if (!validationOfPatientRecord(Employee, true)) {
        return;
      }
      const phone = `${Employee.countryCode}${Employee.phone_number}`;
      const createdPatientUser = await api.createPatientUser({
        groupname: "Employees",
        username: phone,
        phone_number: phone,
        email: Employee.email,
        first: Employee.first,
        last: Employee.last,
        labID: connectedIDInLocalStorage.get(),
        employeeID: result?.id || Employee.id,
      });

      if (createdPatientUser?.userName) {
        setEmployee({
          ...Employee,
          subID: createdPatientUser.userName,
        });
      }

      appContext.showSuccessBottomMessage(createdPatientUser.message);
    } catch (err) {
      appContext.showErrorMessage(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const sendEmailToMember = async (newUser) => {
    try {
      const message = emailMsgToCreatedMember(newUser.first, newUser.phone_number, newUser.password);

      await api.sendEmailToCrew([{ email: newUser.email, subject: "SafeCamp Team", msg: message }]);
    } catch (error) {
      console.log("ERROR createUser: ", error);
    }
  };

  const emailTest = (t) => {
    setLoading(true);
    const test = formatTest(t);
    appContext.getLab(test).then(async (resp) => {
      ReactPDF.pdf(<PDF test={test} testsLab={resp} parseTestResultForPDF={appContext.parseTestResultForPDF} />)
        .toBlob()
        .then(async (data) => {
          try {
            const fileName = `${test.id}-result.pdf`;
            await Storage.put(fileName, data);
            await API.sendEmailToCrew([{ email: test.email, fileName: `public/${fileName}` }]);
            await API.updateEmailStatus(test.id);
            // send sms as well if email is sending first time
            if (!test.emailSend && test.phone_number) {
              let smsMessage = `Hello, your test results are in for your test with ${test.site_name}, please log into Med Flow to review.`;
              const siteObj = appContext.sites.find((s) => s.id === test.siteID);
              if (siteObj && siteObj.messageResulted && result === "negative") {
                smsMessage = siteObj.messageResulted;
              } else if (siteObj && siteObj.messagePositive && result === "positive") {
                smsMessage = siteObj.messagePositive;
              }
              await API.sendSMSNotification(setPhoneNo(test.phone_number), smsMessage);
            }
            setLoading(false);
            appContext.showSuccessMessage("Email has been sent successfully");
          } catch (error) {
            console.log("Error");
            setLoading(false);
          }
        });
    });
  };

  const handleSaveQuarantine = async (obj) => {
    try {
      await API.updateQuarantineData(test.id, obj.startDate, obj.endDate);
      getPatient(test.id);
      window.location.reload();
      appContext.showSuccessMessage("Quarantine dates changed successfully");
    } catch (err) {
      appContext.showErrorMessage(err.message);
    }
  };

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };
  const patternConsentFile = async () => {
    if (!test) return "";
    const siteID = test.baseSiteID || test.siteID;
    const fileName = `${siteID}-${test.id}-pattern.pdf`;
    const result = await Storage.get(fileName, { download: true });
    downloadBlob(result.Body, `${test.barcode}-pattern.pdf`);
  };

  const dataOfBirthParse = (dob) => {
    if (dob && dob !== "Invalid date") {
      return formatDateOfBirthDOB(dob);
    }
    return moment().format("MM/DD/YYYY");
  };

  const showResultPDF = async (test) => {
    const empID = await appContext.getEmployeeID(test.employeeID);
    const t = test;
    t.empID = empID;
    appContext.getLab(test).then(async (resp) => {
      setTestsLab(resp);
      setShowPdf(true);
    });
    setTestPdf(formatTest(test));
  };

  const handleRadioButtonChange = (e) => {
    if (e.target.name === "isVaccinated") {
      if (e.target.value === "yes") {
        setEmployee({ ...Employee, isVaccinated: e.target.checked && true });
      } else {
        setEmployee({ ...Employee, isVaccinated: e.target.checked && false });
      }
    } else {
      if (e.target.value === "yes") {
        setEmployee({ ...Employee, isBooster: e.target.checked && true });
      } else {
        setEmployee({ ...Employee, isBooster: e.target.checked && false });
      }
    }
  };

  const handleToogleChange = (e) => {
    const { name, checked } = e.target;
    const value =
      name === "isCheckedUser" ? checked : name === "status" ? (checked ? "active" : "inactive") : checked ? "1" : "0";
    setEmployee({ ...Employee, [name]: value });
  };

  const makeRelationModal = (e) => {
    console.log("clicked::", e);
    e.preventDefault();
    e.stopPropagation();
    setProfileModal(!showProfileModal);
  };

  const handlePatientResetPassword = async () => {
    setLoading(true);
    try {
      const result = await api.updatePatientPassword(Employee.subID);

      appContext.showSuccessBottomMessage(`Password reset successfully.`);
    } catch (error) {
      console.log("Error", error);
      appContext.showErrorMessage(`Password reset failed.`);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenNewPayerModal = () => {
    setInsuranceModal({
      ...EMP_INS_OBJECT,
      id: getUUID(),
      isPrimary: !Employee.insuranceDetails || Employee.insuranceDetails.length === 0,
    });
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        setInsuranceModal({ ...row, isNew: false });
        break;
      case TABLE_QUICK_TOOLS.delete:
        const stateInsDetails = Employee.insuranceDetails.filter((f) => f.id !== row.id);
        setEmployee({ ...Employee, insuranceDetails: stateInsDetails });
        break;

      default:
        break;
    }
  };

  const handleDeleteRelation = async (item) => {
    try {
      setLoading(true);
      const modifiedRelation = proxyRelations.filter((f) => f.mdID !== item.mdID);
      await API.deleteRelations({
        id: Employee?.id,
        connectedID: item.id,
      });

      dispatch(setProxyRelations(modifiedRelation));
      setLoading(false);
      appContext.showSuccessBottomMessage(`Proxy Profile Deleted successfully.`);
    } catch (err) {
      setLoading(false);
      appContext.showErrorMessage(err.response.data.message);
    }
  };

  const getProviderValue = (providers, Employee) => {
    if (Employee?.referringProvider) {
      return providers.find((item) => item.value === Employee.referringProvider) || null;
    }
    if (providers?.length === 1 && Employee?.isNew) {
      const prov = providers[0];
      setEmployee({
        ...Employee,
        referringProvider: prov?.value || "",
        referringPyhsician: prov?.label || "",
        ...(prov?.associatedClient && { siteID: prov.associatedClient }),
      });
      return providers[0];
    }

    return null;
  };

  return (
    <Modal
      backdrop="static"
      show
      animation={true}
      onHide={handleClose}
      size="2x2"
      style={{ paddingLeft: "0" }}
      centered
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {/* {(Employee?.isNew === false && permission[EMPLOYEE].editPatientRecord) || permission[EMPLOYEE].write
            ? "Update Patient"
            : "Create Patient"} */}

          {Employee?.isNew === false ? "Update Patient" : "Create Patient"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          paddingTop: 0,
        }}
      >
        <div className="parent-wrapper">
          <SweetAlert show={showAlert} success title="Success" onConfirm={hideAlert}>
            Test Released
          </SweetAlert>
          <SweetAlert
            show={showSubmitAlert}
            success
            title="Success"
            onConfirm={() => {
              window.history.back();
              setShowSubmitAlert(false);
            }}
          >
            Test Submitted Successfully.
          </SweetAlert>
          <div className="crew-member-profile">
            <div className="crew-member-profile-inner">
              <div className="d-md-flex  justify-content-between">
                <Col md="3" className="wrapper-profile">
                  <div className="card-body px-3">
                    <div className="profile-image position-relative text-center">
                      <div className="crew-image-wrapper">
                        <figure className="profile-image-wrapper">
                          {!editButton ? (
                            <ImageInput
                              type="profile"
                              setSelectedImage={setProfileImage}
                              selectedImage={profileImage}
                              cameraIcon
                            />
                          ) : (
                            <div className="">
                              <img src={profileImage} alt="profile-image" />
                            </div>
                          )}
                        </figure>
                      </div>
                      <p className="text-center mt-3 text-black mb-0">{`${Employee?.first || ""} ${
                        Employee?.last || ""
                      }`}</p>
                    </div>
                    <hr className="horizontal-line" />
                    <div className="settings-profile">
                      <h4 className="profile-title my-1">Communication Preference</h4>

                      {/* <div className="d-flex align-items-center justify-content-between profile-detail mb-1">
                        <span>SMS</span>
                        <SwitchToggle
                          id="sendSMS"
                          name="sendSMS"
                          handleToogleChange={handleToogleChange}
                          checked={Employee?.sendSMS == "1" ? true : false}
                          disabled={editButton}
                        />
                      </div> */}
                      <div className="d-flex align-items-center justify-content-between profile-detail mb-1">
                        <span>Email</span>
                        <SwitchToggle
                          id="sendEmail"
                          name="sendEmail"
                          handleToogleChange={handleToogleChange}
                          checked={Employee?.sendEmail == "1" ? true : false}
                          disabled={editButton}
                        />
                      </div>

                      {/* <div className="d-flex justify-content-between w-100 flex-wrap">
                        <span>Billing Preferences</span>
                        <RadioButton
                          name="billingpreferences"
                          id={`billing_email`}
                          label="Email"
                          checked={Employee?.setting?.billType === "email" ? true : false}
                          handleRadioButtonChange={(e) =>
                            setEmployee({
                              ...Employee,
                              setting: { ...(Employee.setting || {}), billType: e.target.checked ? "email" : "" },
                            })
                          }
                          disabled={editButton}
                        />
                        <RadioButton
                          name="billingpreferences"
                          id={`billing_mail`}
                          checked={Employee?.setting?.billType === "mail" ? true : false}
                          label="Mail"
                          handleRadioButtonChange={(e) =>
                            setEmployee({
                              ...Employee,
                              setting: { ...(Employee.setting || {}), billType: e.target.checked ? "mail" : "" },
                            })
                          }
                          disabled={editButton}
                        />
                      </div> */}
                      <h4 className="profile-title my-1">Facility Indicator</h4>
                      <div
                        className="d-flex align-items-center justify-content-between profile-
                          detail mb-1"
                      >
                        <span>Hospice</span>
                        <SwitchToggle
                          checked={Employee?.isHospice}
                          handleChange={(e) =>
                            setEmployee({
                              ...Employee,
                              isHospice: e.target.checked,
                            })
                          }
                        />
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-between profile-
                          detail mb-1"
                      >
                        <span>Skilled Nursing Facility</span>
                        <SwitchToggle
                          checked={Employee?.snf}
                          handleChange={(e) =>
                            setEmployee({
                              ...Employee,
                              snf: e.target.checked,
                            })
                          }
                        />
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-between profile-
                          detail mb-1"
                      >
                        <span>Other Institution</span>
                        <SwitchToggle
                          checked={Employee?.isInsHousing}
                          handleChange={(e) =>
                            setEmployee({
                              ...Employee,
                              isInsHousing: e.target.checked,
                            })
                          }
                        />
                      </div>

                      {Employee?.isHospice && (
                        <div
                          className="d-flex align-items-center justify-content-between profile-
                          detail"
                        >
                          <span>Hospice Modifier</span>
                          <div className="d-flex justify-content-between w-40 gap-2">
                            <RadioButton
                              name="GW"
                              id={`GW`}
                              label="GW"
                              checked={Employee?.codes?.hospiceModifer === "GW" ? true : false}
                              handleRadioButtonChange={(e) =>
                                setEmployee({
                                  ...Employee,
                                  codes: { hospiceModifer: e.target.checked ? "GW" : "" },
                                })
                              }
                              disabled={editButton}
                            />
                            <RadioButton
                              name="GV"
                              id={`GV`}
                              label="GV"
                              checked={Employee?.codes?.hospiceModifer === "GV" ? true : false}
                              handleRadioButtonChange={(e) =>
                                setEmployee({
                                  ...Employee,
                                  codes: { hospiceModifer: e.target.checked ? "GV" : "" },
                                })
                              }
                              disabled={editButton}
                            />
                          </div>
                        </div>
                      )}
                      <h4 className="profile-title my-1">Other Settigs</h4>

                      <div
                        className="d-flex align-items-center justify-content-between profile-
                          detail mb-1"
                      >
                        <span>Auto Release</span>
                        <SwitchToggle
                          id="autoRelease"
                          name="autoRelease"
                          handleToogleChange={handleToogleChange}
                          checked={Employee?.autoRelease == "1" ? true : false}
                          disabled={editButton}
                        />
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-between profile-
                          detail mb-1"
                      >
                        <span>Active</span>
                        <SwitchToggle
                          id="status"
                          name="status"
                          handleToogleChange={handleToogleChange}
                          checked={Employee?.status === "active" ? true : false}
                          disabled={editButton}
                        />
                      </div>
                      {Employee &&
                        (Employee?.isNew ? (
                          <div
                            className="d-flex align-items-center justify-content-between profile-
                          detail mb-1"
                          >
                            <span>Portal Access?</span>
                            <SwitchToggle
                              id="isCheckedUser"
                              name="isCheckedUser"
                              handleToogleChange={handleToogleChange}
                              checked={Employee?.isCheckedUser}
                              disabled={editButton}
                            />
                          </div>
                        ) : !Employee?.subID ? (
                          permission[EMPLOYEE]?.write && (
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Portal Access</span>
                              <span
                                className="modalButtons cursor-pointer text-decoration-underline text-primary"
                                variant="secondary"
                                onClick={() => createPatientLogin(Employee)}
                              >
                                Create User
                              </span>
                            </div>
                          )
                        ) : (
                          permission[EMPLOYEE]?.write && (
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Portal Access</span>
                              <span
                                className="modalButtons cursor-pointer text-decoration-underline text-primary"
                                variant="secondary"
                                onClick={handlePatientResetPassword}
                              >
                                Reset Password
                              </span>
                            </div>
                          )
                        ))}

                      {/* {Employee &&
                        (Employee?.isNew ? (
                          <div
                            className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                          >
                            <span>Portal Access?</span>
                            <SwitchToggle
                              id="isCheckedUser"
                              name="isCheckedUser"
                              handleToogleChange={handleToogleChange}
                              checked={Employee?.isCheckedUser}
                              disabled={editButton}
                            />
                          </div>
                        ) : !Employee?.subID ? (
                          permission[EMPLOYEE]?.write && (
                            <Button
                              className="modalButtons"
                              variant="secondary"
                              onClick={() => createPatientLogin(Employee)}
                            >
                              Create Patient User
                            </Button>
                          )
                        ) : (
                          permission[EMPLOYEE]?.write && (
                            <Button className="modalButtons" variant="secondary" onClick={handlePatientResetPassword}>
                              Reset Password
                            </Button>
                          )
                        ))} */}
                    </div>
                    <div className="wrapper-profile settings-profile textarea border-0 mt-2 p-0">
                      <h4 className="profile-title my-1 px-2">Notes</h4>
                      <div className="mb-1">
                        <textarea
                          readOnly={editButton}
                          className="form-control"
                          onChange={(e) => setEmployee({ ...Employee, note: e.target.value })}
                          value={Employee?.note || ""}
                          defaultValue={demographics?.note}
                          placeholder={`${!editButton ? "Enter Notes" : ""}`}
                          type="text"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="9" className="border-left-1">
                  <div className="personal-info-wrapper px-3">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <h4 className="text-decoration-underline mt-0 section-title">Personal Info</h4>
                      <div style={{ display: "flex" }}>
                        <h4 className="mt-0 section-title mr-2">Outstanding Balance:</h4>
                        <h4 className="text-decoration-underline mt-0 currency-view">
                          {formatCurrency(Employee?.setting?.outstanding_balance)}
                        </h4>
                      </div>
                    </div>
                    <div className="testAdmin createClientsWrapper">
                      <div className="crew-profile-info">
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="First Name"
                          value={Employee?.first || ""}
                          index="first"
                          maxLength={100}
                          placeholder="Enter First Name"
                          disabled={editButton}
                          handleChange={(e) =>
                            setEmployee({
                              ...Employee,
                              first: getValidDep(e.target.value),
                            })
                          }
                          required={requiredKeysObj["first"] || false}
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Middle Name"
                          value={Employee?.middleName || ""}
                          index="middle"
                          maxLength={100}
                          placeholder="Enter Middle Name"
                          disabled={editButton}
                          handleChange={(e) =>
                            setEmployee({
                              ...Employee,
                              middleName: getValidDep(e.target.value),
                            })
                          }
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Last Name"
                          value={Employee?.last || ""}
                          index="last"
                          maxLength={100}
                          placeholder="Enter Last Name"
                          disabled={editButton}
                          handleChange={(e) =>
                            setEmployee({
                              ...Employee,
                              last: getValidDep(e.target.value),
                            })
                          }
                          required={requiredKeysObj["last"] || false}
                        />

                        <InputField
                          type="email"
                          inputStyle="modalInput"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Email"
                          value={Employee?.email || ""}
                          index="email"
                          maxLength={25}
                          placeholder="Enter Email"
                          disabled={editButton}
                          required={requiredKeysObj["email"] || false}
                          handleChange={(e) => {
                            let updatedEmployee = { ...Employee, email: convertToLower(e.target.value) };
                            updatedEmployee = updateContactPreferences(updatedEmployee);
                            setEmployee(updatedEmployee);
                          }}
                        />
                      </div>
                      <div className="crew-profile-row4 align-items-center">
                        <InputField
                          type="phone"
                          inputStyle={`employee-phone-input ${Employee?.isNew && "pd-right-30"}`}
                          labelStyle="modalLineHeaders mt-0 text-capitalize"
                          label="Phone Number"
                          value={formatPhoneNumber(Employee?.phone_number, Employee?.countryCode || "+1")}
                          index="phone_number"
                          maxLength={65}
                          placeholder="Enter Phone Number"
                          disabled={editButton}
                          handleChange={handlePhoneNo}
                          required={requiredKeysObj["phone_number"] || false}
                        />
                        <InputField
                          type="datePicker"
                          inputStyle="w-100 orderTestDob"
                          labelStyle="modalLineHeaders mt-0 text-capitalize"
                          label="Date of Birth"
                          value={
                            Employee?.dob && new Date(Employee.dob) != "Invalid Date"
                              ? moment(Employee.dob, "YYYY-MM-DD").toDate()
                              : null
                          }
                          index="dob"
                          placeholder="Date of Birth"
                          disabled={editButton}
                          handleChange={(e) =>
                            setEmployee({
                              ...Employee,
                              dob:
                                moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                            })
                          }
                          required={requiredKeysObj["dob"] || false}
                        />
                        <div>
                          <label className="my-0 modalLineHeaders">
                            Gender <span className="fw-bold fs-6 maroon">{requiredKeysObj["sex"] && "*"}</span>
                          </label>
                          <div className={`d-flex justify-content-between w-100 flex-wrap`}>
                            <RadioButton
                              name="sex"
                              id={`male${Math.random()}`}
                              label="Male"
                              checked={Employee?.sex === "M" ? true : false}
                              handleRadioButtonChange={(e) =>
                                setEmployee({
                                  ...Employee,
                                  sex: e.target.checked ? "M" : "",
                                })
                              }
                              disabled={editButton}
                            />
                            <RadioButton
                              name="sex"
                              id={`female${Math.random()}`}
                              checked={Employee?.sex === "F" ? true : false}
                              label="Female"
                              handleRadioButtonChange={(e) =>
                                setEmployee({
                                  ...Employee,
                                  sex: e.target.checked ? "F" : "",
                                })
                              }
                              disabled={editButton}
                            />
                            <RadioButton
                              id={`other${Math.random()}`}
                              checked={Employee?.sex === "X" ? true : false}
                              name="sex"
                              label="Not Specified"
                              handleRadioButtonChange={(e) =>
                                setEmployee({
                                  ...Employee,
                                  sex: e.target.checked ? "X" : "",
                                })
                              }
                              disabled={editButton}
                            />
                            <RadioButton
                              id={`other${Math.random()}`}
                              checked={Employee?.sex === "N" ? true : false}
                              name="sex"
                              label="Not Binary"
                              handleRadioButtonChange={(e) =>
                                setEmployee({
                                  ...Employee,
                                  sex: e.target.checked ? "N" : "",
                                })
                              }
                              disabled={editButton}
                            />
                          </div>
                        </div>
                        {/* <div className="form-group-wrapper my-0 flex-2">
                          <label className="my-0 modalLineHeaders">
                            Gender <span className="fw-bold fs-6 maroon">*</span>
                          </label>
                          <div className="d-flex justify-content-between w-100 flex-wrap">
                            
                          </div>
                        </div> */}
                      </div>
                      <div className="crew-profile-row4">
                        <InputField
                          type="address"
                          groupWrapper="form-group-wrapper w-100"
                          inputStyle="modalInput"
                          labelStyle="modalLineHeaders text-capitalize"
                          label="Address"
                          value={Employee?.street || ""}
                          index="street"
                          maxLength={255}
                          placeholder="Enter Address"
                          disabled={editButton}
                          handleChange={(val, flag) => handleUpdateAddress(val, flag)}
                        />
                        <InputField
                          type="text"
                          groupWrapper="form-group-wrapper w-100"
                          inputStyle="modalInput"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Apt/Unit#"
                          value={Employee?.street2 || ""}
                          index="street2"
                          maxLength={255}
                          placeholder="Enter Apt/Unit"
                          disabled={editButton}
                          handleChange={(e) => setEmployee({ ...Employee, street2: e.target.value })}
                        />
                        <InputField
                          type="text"
                          groupWrapper="form-group-wrapper w-100"
                          inputStyle="modalInput"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="City"
                          value={Employee?.city || ""}
                          index="city"
                          maxLength={65}
                          placeholder="Enter City"
                          disabled={editButton}
                          handleChange={(e) => setEmployee({ ...Employee, city: e.target.value })}
                        />
                      </div>
                      <div className="crew-profile-row-exact4">
                        <InputField
                          type="text"
                          groupWrapper="form-group-wrapper w-100"
                          inputStyle="modalInput"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="State"
                          value={Employee?.state || ""}
                          index="state"
                          maxLength={2}
                          placeholder="Enter State"
                          disabled={editButton}
                          handleChange={(e) => {
                            let val = e.target.value;
                            if (val) val = val.toUpperCase();
                            setEmployee({
                              ...Employee,
                              state: getValidSpaces(val),
                            });
                          }}
                        />
                        <InputField
                          type="text"
                          groupWrapper="form-group-wrapper w-100"
                          inputStyle="modalInput"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Zip Code"
                          value={Employee?.zip || ""}
                          index="zip"
                          maxLength={10}
                          placeholder="Enter Zip Code"
                          disabled={editButton}
                          handleChange={(e) => {
                            let val = e.target.value;
                            if (val) val = val.toUpperCase();
                            setEmployee({
                              ...Employee,
                              zip: Employee.isoCode === "US" ? val : getValidSpaces(val),
                            });
                          }}
                        />
                        <InputField
                          type="dropDown"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Country"
                          options={countriesOption}
                          value={
                            Employee?.country
                              ? {
                                  label: Employee.country,
                                  value: Employee.country,
                                }
                              : null
                          }
                          index="country"
                          maxLength={20}
                          placeholder="Select Country"
                          handleChange={(e) => {
                            const country = countryListAllIsoData.find((f) => f.name === e.value) || { code: "US" };
                            setEmployee({
                              ...Employee,
                              country: e.value,
                              isoCode: country.code,
                            });
                          }}
                        />
                        {!CONFIG?.siteID && (
                          <InputField
                            type="dropDown"
                            inputStyle="referring-provider-select w-100"
                            labelStyle="mt-0 modalLineHeaders text-capitalize"
                            label="Client"
                            options={clientOptions}
                            required={true}
                            value={Employee?.provider || null}
                            index="patientsite"
                            placeholder="Select Client"
                            disabled={editButton}
                            isClearable={true}
                            isMulti
                            handleChange={(e) => {
                              console.log("E", e);

                              setEmployee({
                                ...Employee,
                                provider: e,
                                ...(Array.isArray(e) &&
                                  e.length > 0 && {
                                    siteID: e[0].value,
                                    site_name: e[0].label,
                                  }),
                              });
                            }}
                          />
                        )}
                        {/* <InputField
                          type="dropDown"
                          inputStyle="referring-provider-select w-100"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Referring Physician"
                          options={providers}
                          required={requiredKeysObj["referringPyhsician"] || false}
                          value={getProviderValue(providers, Employee)}
                          index="referringProvider"
                          placeholder="Select Referring Physician"
                          disabled={editButton}
                          isClearable={true}
                          handleChange={(e) => {
                            setEmployee({
                              ...Employee,
                              referringProvider: e?.value || "",
                              referringPyhsician: e?.label || "",
                              ...(e?.associatedClient && { siteID: e?.associatedClient }),
                            });
                          }}
                        /> */}
                        {/* 
                      <Form.Group className="form-group-wrapper" readOnly={editButton} plaintext>
                        {calculate_age(demographics?.dob) > 18 ? (
                          <label className="modalLineHeaders mt-0 text-capitalize">ID Number</label>
                        ) : (
                          <label>SSN</label>
                        )}
                        <Form.Control
                          readOnly={editButton}
                          className={`${!editButton ? "modalInput" : ""}`}
                          value={Employee?.id_number}
                          onChange={(e) => setEmployee({ ...Employee, id_number: e.target.value })}
                          defaultValue={formatIDNo()}
                          placeholder="ID Number"
                          type="text"
                        />
                      </Form.Group> */}
                      </div>
                      <div className="crew-profile-row4">
                        {/* <InputField
                          type="dropDown"
                          inputStyle="w-100"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Rendering Provider"
                          options={providers}
                          value={
                            Employee?.renderingProvider
                              ? providers.find((item) => item.value === Employee.renderingProvider)
                              : null
                          }
                          index="renderingProvider"
                          isClearable={true}
                          placeholder="Select Rendering Provider"
                          disabled={editButton}
                          handleChange={(e) => {
                            setEmployee({
                              ...Employee,
                              renderingProvider: e?.value || "",
                            });
                          }}
                        /> */}
                        {/* <InputField
                          type="dropDown"
                          inputStyle="referring-provider-select w-100"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Referring Physician"
                          options={providers}
                          value={
                            Employee?.referringProvider
                              ? providers.find((item) => item.value === Employee.referringProvider)
                              : null
                          }
                          index="referringProvider"
                          placeholder="Select Referring Physician"
                          disabled={editButton}
                          isClearable={true}
                          handleChange={(e) => {
                            setEmployee({
                              ...Employee,
                              referringProvider: e?.value || "",
                            });
                          }}
                        /> */}
                        {/* <InputField
                          type="dropDown"
                          inputStyle="w-100"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Primary Care Physician"
                          options={providers}
                          value={
                            Employee?.primaryCarePhysician
                              ? providers.find((item) => item.label === Employee.primaryCarePhysician)
                              : null
                          }
                          index="primaryCarePhysician"
                          placeholder="Select Primary Care Physician"
                          disabled={editButton}
                          isClearable={true}
                          handleChange={(e) => {
                            setEmployee({
                              ...Employee,
                              primaryCarePhysician: e?.label || "",
                            });
                          }}
                        /> */}
                        {/* <InputField
                          type="dropDown"
                          inputStyle="w-100"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Ordering Provider"
                          options={providers}
                          value={
                            Employee?.orderingProvider
                              ? providers.find((item) => item.value === Employee.orderingProvider)
                              : null
                          }
                          index="orderingProvider"
                          placeholder="Select Ordering Provider"
                          disabled={editButton}
                          isClearable={true}
                          handleChange={(e) => {
                            setEmployee({
                              ...Employee,
                              orderingProvider: e?.value || "",
                            });
                          }}
                        /> */}
                      </div>
                      <div className="crew-profile-row4 mb-3"></div>
                    </div>
                  </div>
                  <div className="p-3 pt-0">
                    <Row className="additional-info w-100 mx-0">
                      <Col md="6" className="border-right-1 additional-info-radios">
                        <div className="justify-content-between d-flex  w-100 mb-0 m-0 secondary-insurance-head">
                          <h4 className="text-decoration-underline mt-0 section-title">
                            Payer Information{" "}
                            <span className="fw-bold fs-6 maroon">
                              {requiredKeysObj["insuranceCompanyCode"] && "*"}
                            </span>
                          </h4>
                          <div className="plus-icon mt-1">
                            <i
                              className="fas fa-plus-circle fa-lg cursor-pointer w-auto"
                              // onClick={() => setInsuranceModal(EMP_INS_OBJECT)}
                              onClick={handleOpenNewPayerModal}
                            />
                          </div>
                        </div>

                        <div className="w-100 py-2">
                          {Employee?.insuranceDetails && Employee.insuranceDetails.length > 0
                            ? Employee.insuranceDetails.map((ins, i) => (
                                <InsuranceViewCard
                                  insurance={{ ...ins }}
                                  index={i + 1}
                                  key={i}
                                  handleEdit={(obj) => {
                                    setInsuranceModal({ ...obj, isNew: false });
                                  }}
                                  user={Employee}
                                  handleUpdateIns={(obj) => {
                                    const findIndex = Employee.insuranceDetails.findIndex((f) => f.id === obj.id);
                                    if (findIndex !== -1) {
                                      const stateUser = { ...Employee };
                                      const stateInsDetails = [...stateUser.insuranceDetails];
                                      stateInsDetails[findIndex] = obj;
                                      setEmployee({ ...stateUser, insuranceDetails: stateInsDetails });
                                    }
                                  }}
                                  handleRemove={(obj) => {
                                    const stateInsDetails = Employee.insuranceDetails.filter((f) => f.id !== obj.id);
                                    setEmployee({ ...Employee, insuranceDetails: stateInsDetails });
                                  }}
                                  userClientData={userClientData}
                                  cssClass={"insCard-background-blue "}
                                />
                              ))
                            : null}
                        </div>
                        {/* <InputField
                        type="dropDown"
                        inputStyle="w-100"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Insurance Carrier"
                        options={INSURANCE_PROVIDER}
                        value={
                          Employee?.insurance_name
                            ? INSURANCE_PROVIDER.find((f) => f.label === Employee?.insurance_name)
                            : null
                        }
                        disabled={editButton}
                        index="insurance_name"
                        placeholder="Select Insurance"
                        handleChange={(e) => {
                          setEmployee({ ...Employee, insurance_name: e.label });
                        }}
                      />

                      <Form.Group className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">Insurance ID</label>
                        <Form.Control
                          className={`height-32 ${!editButton ? "modalInput" : ""}`}
                          readOnly={editButton}
                          onChange={(e) => setEmployee({ ...Employee, insurance_number: e.target.value })}
                          value={Employee?.insurance_number}
                          defaultValue={demographics?.insurance_number}
                          placeholder={`${!editButton ? "Enter Insurance Id" : ""}`}
                          type="text"
                        />
                      </Form.Group> */}
                        <div className="w-100">
                          {showImages && (
                            <div className="d-block">
                              <div className="cards-heading">
                                <span>Insurance</span> Card Images
                              </div>
                              <div className="test-details-wrapper rounded-2">
                                <div className="imageWrapper">
                                  {showImages.filter((i) => i.isHIPPA === false && i.isExist).length > 0 ? (
                                    showImages
                                      .filter((i) => i.isHIPPA === false && i.isExist)
                                      .map((showImg, index) => {
                                        return (
                                          <ImageView
                                            key={index}
                                            cssClass="border-0 p-3"
                                            imgSrc={showImg.imgSrc}
                                            onClick={() => handleImageClick(showImg.imgSrc)}
                                          />
                                        );
                                      })
                                  ) : (
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                      <ImageView key={0} cssClass="border-0 p-3" imgSrc={""} />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                      {/* <Col md="4" className="testAdmin createClientsWrapper border-right-1 align-items-start">
                        <div className="justify-content-between d-flex align-items-center mb-0 m-0 secondary-insurance-head">
                          <h4 className="text-decoration-underline mt-0 section-title">Additional Insurance Details</h4>
                        </div>
                      </Col> */}

                      <Col md="6" className="additional-info-radios border-right-1">
                        <div className="justify-content-between d-flex align-items-center mb-0 m-0 secondary-insurance-head">
                          <h4 className="text-decoration-underline mt-0 section-title mb-0">Documents</h4>
                          <div className="plus-icon mx-2">
                            <i
                              className={`fas fa-plus-circle fa-lg cursor-pointer w-auto ${
                                editButton && "disable-cursor"
                              }`}
                              onClick={() =>
                                !editButton && (setSelectedDocument({ isNew: true }), setOpenDocumentModal(Employee))
                              }
                            />
                          </div>
                        </div>

                        <DocumentList
                          list={documents}
                          disabled={editButton}
                          setDocuments={setDocuments}
                          dropDownOptions={[{ title: "Download" }, { title: "Edit" }, { title: "Delete" }]}
                          appContext={appContext}
                          setLoading={setLoading}
                          handleEdit={(item) =>
                            !editButton &&
                            (setSelectedDocument({ ...item, isNew: false }), setOpenDocumentModal(Employee))
                          }
                        />
                      </Col>
                    </Row>
                    {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
                  </div>
                  <Row className="px-3">
                    <Col>
                      <div className="proxy-box-shadow">
                        <div className="d-flex flex-row align-items-center">
                          <h4 className="text-decoration-underline mt-0 section-title me-2">Proxy Profiles</h4>
                          {Employee?.isNew === false && (
                            <img
                              src={EditIcon}
                              alt="edit icon"
                              className="ml-2 mb-2"
                              width="18"
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              aria-hidden="true"
                              disabled={editButton}
                              onClick={() => setProfileModal(true)}
                            />
                          )}
                        </div>
                        <RelationView relations={proxyRelations} handleDeleteRelation={handleDeleteRelation} />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={modalShow}
          // style={{ transform: "translate(0, -30%)" }}
          animation={true}
          onHide={() => setModalShow(false)}
          centered
          size={"md"}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Release Test Results</Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
            <p>Select all that apply: </p>
            <div className="checkboxRow">
              <label className="checkboxLabel">
                <input
                  disabled={!siteContactPhone}
                  value="site"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setReleaseTo({
                        ...releaseTo,
                        [e.target.value]: siteContactPhone,
                      });
                    } else {
                      const newReleaseTo = releaseTo;
                      delete newReleaseTo[e.target.value];
                      setReleaseTo(newReleaseTo);
                    }
                  }}
                  className="releaseCheckbox"
                  type="checkbox"
                />
                Health and Safety Officer
              </label>
              <label>
                <input
                  value="crew"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setReleaseTo({
                        ...releaseTo,
                        [e.target.value]: setPhoneNo(test?.phone_number),
                      });
                    } else {
                      const newReleaseTo = releaseTo;
                      delete newReleaseTo[e.target.value];
                      setReleaseTo(newReleaseTo);
                    }
                  }}
                  className="releaseCheckbox"
                  type="checkbox"
                />
                Crew Member
              </label>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button className="modalButtons" variant="secondary" onClick={() => setModalShow(false)}>
              Cancel
            </Button>
            <Button
              className="modalButtons"
              variant="primary"
              onClick={async () => {
                if (releaseTo.length === 0) return;
                setLoading(true);
                const params = [];
                params.push({
                  id: test.id,
                  status: "Processed",
                  timerStatus: "Processed",
                  sequenceNo: test.sequenceNo,
                  invalidResultStatus: 1,
                });
                const ids = [{ id: test.id, sequenceNo: test.sequenceNo }];
                await GQAPI.releaseTest(params);
                if (releaseTo.crew) {
                  await GQAPI.sendEmailToPendingRelease(params);
                }

                const logs = {
                  ids: ids,
                  userID: appContext.user?.sub,
                  userName: appContext.user?.name,
                  slug: test.result.toLowerCase() !== "positive" ? "Inconclusive Release" : "Pending Release",
                };

                await GQAPI.addTestLogs(logs);

                releaseTo.site &&
                  (await API.sendSMSNotification(
                    releaseTo.site,
                    `Hello, there was a positive (${TEST_TYPE_VALUE[test.test_type]}) test result for ${
                      site.name
                    }. please login to your Health & Safety Portal to review.`
                  ));

                await appContext.resetPendingTests([test.id]);
                props.history.push("/admin/pendingrelease");
                appContext.showSuccessMessage(`Success! You have released test`);
                setLoading(false);
              }}
            >
              Release Results
            </Button>
          </Modal.Footer>
        </Modal>

        {openDataChangeConfirmation && (
          <ConfirmationModal
            show={openDataChangeConfirmation}
            title="Test Update Alert"
            message={`New Changes occures, are you want to update your edit info!`}
            handleConfirm={handleDataChangeConfirm}
          />
        )}

        {insuranceModal && (
          <InsuranceDetailsModal
            type=""
            title={insuranceModal.isNew ? "Add Payer Information" : "Update Payer information"}
            insuranceDetails={{ ...insuranceModal }}
            insuranceList={Employee.insuranceDetails}
            user={Employee}
            handleUpdateIns={(obj) => {
              setInsuranceModal(null);
              let newInsurances = [];

              if (!Employee?.insuranceDetails || Employee.insuranceDetails.length === 0) {
                newInsurances = [obj];
              } else if (obj.isPrimary) {
                newInsurances = Employee.insuranceDetails
                  .filter((item) => item.id !== obj.id)
                  .map((item) => ({ ...item, isPrimary: false }));
                newInsurances = [obj, ...newInsurances];
              } else {
                const index = Employee.insuranceDetails.findIndex((item) => item.id === obj.id);
                if (index !== -1) {
                  newInsurances = [...Employee.insuranceDetails];
                  newInsurances[index] = obj;
                } else {
                  newInsurances = [...Employee.insuranceDetails, obj];
                }
              }

              setEmployee({
                ...Employee,
                insuranceDetails: newInsurances,
              });
            }}
            handleClose={(key) => {
              setInsuranceModal(null);
            }}
          />
        )}

        {openDocumentModal && (
          <DocumentForm
            emp={openDocumentModal}
            document={selectedDocument}
            handleClose={() => {
              setOpenDocumentModal(null);
            }}
            setLoading={setLoading}
            appContext={appContext}
            setDocuments={setDocuments}
            cssClass="seperateModal"
          />
        )}

        {newProviderInfo && (
          <ProviderModal
            cssClass="seperateModal"
            show={true}
            user={newProviderInfo}
            handleClose={(obj) => {
              setNewUserProvider(null);
              if (obj && obj.id) {
                // setProviders((prev) => [...prev, { ...obj, label: obj.name, value: obj.id }]);
              }
            }}
            providers={providers}
            appContext={appContext}
          />
        )}

        {showProfileModal && (
          <ProfileRelationModal
            employeesId={Employee?.id}
            patient={Employee}
            handleChangeRelations={setRelations}
            handleClose={() => {
              setProfileModal(!showProfileModal);
            }}
          />
        )}

        {loading && <Loader />}
      </Modal.Body>
      <Modal.Footer>
        {!editButton && (
          <Button
            className="profileButton btn-fill createButtons"
            style={{
              marginRight: 10,
              marginTop: 10,
            }}
            variant="primary"
            onClick={handleClose}
          >
            Close
          </Button>
        )}
        <div className="text-right d-flex">
          {Employee?.isNew === false && userClientData?.setting?.eligibility && (
            <>
              <Button
                className="profileButton btn-fill createButtons"
                style={{
                  marginRight: 10,
                  marginTop: 10,
                  whiteSpace: "nowrap",
                }}
                variant="secondary"
                onClick={() => handleCheckEligibility(Employee)}
              >
                Check Eligibility
              </Button>
            </>
          )}

          <Button
            variant="secondary"
            className="modalButtons w-100  headerButton btn-fill"
            style={{
              marginRight: 10,
              marginTop: 10,
            }}
            disabled={editButton}
            onClick={() => {
              dispatch(setOpenInsuranceModal(true));
              dispatch(setSelectedInsurance({ ...INSURANCE_EMPTY_OBJECT, clientID: userCompanyID.get() }));
            }}
          >
            Add Payer
          </Button>
          {!editButton && (
            <Button
              variant="secondary"
              className="profileButton btn-fill createButtons"
              style={{
                marginRight: 10,
                marginTop: 10,
              }}
              disabled={editButton}
              onClick={() => {
                const obj = NEW_PROVIDER_OBJECT;
                setNewUserProvider({ ...obj, isNew: true });
              }}
            >
              Add Provider
            </Button>
          )}

          {editButton && (
            <Button
              className="profileButton btn-fill createButtons"
              style={{
                marginRight: 10,
                marginTop: 10,
              }}
              variant="primary"
              onClick={() => setEditButton(false)}
            >
              Edit Info
            </Button>
          )}

          {!editButton && (permission[EMPLOYEE]?.editPatientRecord || permission[EMPLOYEE]?.write) && (
            <Button
              className="profileButton btn-fill createButtons"
              style={{
                marginRight: 10,
                marginTop: 10,
              }}
              variant="secondary"
              onClick={() => handleSave({ ...Employee })}
            >
              {Employee?.isNew ? "Create" : "Update"}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PatientProfileModal;
